(function () {
  'use strict';

  angular
  .module('neo.home.ajuntaments.ajuntament.informacio.image')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('neo.home.ajuntaments.ajuntament.informacio.image', {
      url: '/image',
      templateUrl: 'home/ajuntaments/ajuntament/informacio/image/image.tpl.html',
      controller: 'ImageCtrl',
      controllerAs: 'vm'
    });
  }
}());
